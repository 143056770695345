<script>
import Detail from '@/modules/clue/component/detail.vue'
import DefeatFrom from './defeatFrom.vue'
import ClueDefeatServices from '@/services/clueDefeatServices'

export default {
  components: { DefeatFrom, Detail },
  data() {
    return {
      id: '',
      defeatId: '',
      visible: false,
      applyUserRole:''
    }
  },
  created() {
    const { id, defeatId ,applyUserRole } = this.$route.query
    this.id = id
    this.defeatId = defeatId // 审核id
    this.applyUserRole = applyUserRole
  },
  methods: {
    handlerConfirm(action, done) {
      if (action === 'confirm') {
        this.$refs.defeatFromRef.validate().then(async () => {
          await this.save()
          done()
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    async save() {
      const params = this.$refs.defeatFromRef.getData()
      params.ids = [this.defeatId]
      await ClueDefeatServices.failedApproval(params)
      this.$router.go(-1)
    },
    // 审核
    handlerAudit() {
      this.visible = true
    },
  }
}
</script>

<template>
<div v-if="id" :class="['page', data?.fail?.type === 1 && data?.fail?.reviewStatus === '1013001'? 'page_pd' : '']">
  <Detail :id="id">
    <template v-slot:btns="{data}">
      <div v-if="data?.fail?.type === 1 && data?.fail?.reviewStatus === '1013001'" class="btns">
        <van-button round class="btn" type="primary" @click="handlerAudit">{{$t('战败审核')}}</van-button>
      </div>
    </template>
  </Detail>
  <van-dialog v-model="visible" show-cancel-button :before-close="handlerConfirm" confirmButtonColor="#B9921A"
    :cancel-button-text="$t('取消')" 
    :confirm-button-text="$t('确认')">
    <DefeatFrom v-if="visible" ref="defeatFromRef" :applyUserRole="applyUserRole"></DefeatFrom>
  </van-dialog>
</div>
</template>

<style scoped lang="less">
.page {
  padding: 16px 16px 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  &.page_pd{
    padding-bottom: 96px
  }
  /deep/ .btns {
    max-width: 500px;
    margin-left: -16px;
    width: 100%;
    height: 96px;
    display: flex;
    position: fixed;
    z-index: 999;
    bottom: 0px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    justify-content: space-between;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: #0D171A;
    background: #fff;
    box-shadow: 0px 2px 8px 2px #ddd;
    .btn {
      width: 100% !important;
    }
  }
}
</style>
